import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

function SuspenseIndicator() {
  const styles = {
    container: { marginTop: "1rem", marginBottom: "1rem" },
    label: { margin: "0.5rem" }
  }
  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={styles.container}>
      <CircularProgress disableShrink size={32} />
      <div style={styles.label}>Carregando...</div>
    </Box>
  )
}

export default SuspenseIndicator
